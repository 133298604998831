<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="BookIcon" size="19"/>
          <h4 class="mb-0 ml-50">Masraf Formu Bilgileri</h4>
        </div>

        <b-row class="mt-1">
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Kullanıcı" rules="required">
              <b-form-group label="* Kullanıcı" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.userId" :clearable="false" :options="userOptions" label="label" :reduce="val => val.value" disabled="disabled">
                  <template #option="{ avatar, label }">
                    <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                  <template #selected-option="{ avatar, label }">
                    <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Belge Tarihi" rules="required">
              <b-form-group label="* Belge Tarihi" :state="getValidationState(validationContext)">
                <flatpickr v-model="dataInfo.docDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1, minDate: minDocDate, maxDate: 'today'}"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4"></b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Masraf Tipi" rules="required">
              <b-form-group label="* Masraf Tipi" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.expenseTypeId" :options="expenseTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Ödeme Tipi" rules="required">
              <b-form-group label="* Ödeme Tipi" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.paymentTypeId" :options="paymentTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4"></b-col>
        </b-row>

        <div class="d-flex">
          <feather-icon icon="CreditCardIcon" size="16"/>
          <h5 class="mb-0 ml-50">Tutar Bilgileri</h5>
        </div>

        <b-row class="mt-1 mb-1">
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="KDV" rules="required">
              <b-form-group label="* KDV">
                <b-form-input trim placeholder="KDV" v-model="dataInfo.totalVat" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Genel Toplam" rules="required">
              <b-form-group label="* Genel Toplam">
                <b-form-input trim placeholder="Genel Toplam" v-model="dataInfo.totalNet" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Para Birimi" rules="required">
              <b-form-group label="* Para Birimi" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.currencyTypeId" :options="currencyTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div class="d-flex">
          <feather-icon icon="InfoIcon" size="16"/>
          <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
        </div>

        <b-row class="mt-1 mb-1">
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Döküman">
              <b-form-group label="Döküman" description="Birden fazla döküman seçimi yapabilirsiniz...">
                <b-form-file id="receiptFiles" name="receiptFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea rows="1" trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <action-buttons :back-route="'form-expense-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTextarea, BAvatar, BFormFile
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/form/expense/store"
import definitionModule from "@/views/system/definition/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import flatPickr from 'vue-flatpickr-component'
import userModule from "@/views/organization/employee/store"
import {avatarText, getApiFile} from "@core/utils/filter"
import flatpickr from "vue-flatpickr-component/src/component";

export default {
  components: {
    BFormFile,
    flatpickr,
    BAvatar,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    flatPickr,

    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      userId: JSON.parse(localStorage.getItem('userData')).id,
      docDate: null,
      expenseTypeId: null,
      totalVat: null,
      totalNet: null,
      currencyTypeId: null,
      paymentTypeId: null,
      description: '',
      bypassWorkflowCheck: false
    })

    const expenseTypeOptions = ref([])
    const currencyTypeOptions = ref([])
    const paymentTypeOptions = ref([])

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'EXPENSE_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        expenseTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.expenseTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'CURRENCY_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        currencyTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.currencyTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'PAYMENT_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        paymentTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.paymentTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const userOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          if (!userOptions.value.some(e => e.value === value.id)) {
            userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture})
          }
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'form-expense-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      const documents = document.getElementById('receiptFiles').files
      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        let file = documents[i];
        formData.append('documents[' + i + ']', file);
      }

      formData.append('id', dataInfo.value.id);
      formData.append('userId', dataInfo.value.userId);
      formData.append('docDate', dataInfo.value.docDate);
      formData.append('expenseTypeId', dataInfo.value.expenseTypeId);
      formData.append('totalVat', dataInfo.value.totalVat);
      formData.append('totalNet', dataInfo.value.totalNet);
      formData.append('currencyTypeId', dataInfo.value.currencyTypeId);
      formData.append('paymentTypeId', dataInfo.value.paymentTypeId);
      formData.append('description', dataInfo.value.description);
      formData.append('bypassWorkflowCheck', dataInfo.value.bypassWorkflowCheck);

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), formData).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'form-expense-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    var pastDay = JSON.parse(localStorage.getItem('employeeData')).settings.filter(function (creature) {
      return creature.key === "PastDay";
    })[0].value;

    var minDocDate = new Date();
    minDocDate.setDate(minDocDate.getDate() - parseInt(pastDay));

    return {
      busy,
      dataInfo,
      refFormObserver,
      expenseTypeOptions,
      userOptions,
      currencyTypeOptions,
      minDocDate,
      paymentTypeOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
